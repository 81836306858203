<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="资方名称">
          <el-input v-model="searchData.capitalName" size="mini" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.capitalMobile" size="mini" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="创建日期">
          <el-date-picker
            style="width:230px"
            v-model="timeSlot"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            size="mini"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList()">搜索</el-button>
          <el-button type="info" icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="head-btn">
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.capital_list_add)"
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="handleAdd"
        >创建账号</el-button
      >
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.capital_list_export)"
        type="primary"
        icon="el-icon-download"
        size="mini"
        @click="handleExport"
        >导出</el-button
      >
    </div>

    <el-table :data="list" style="width: 100%">
      <el-table-column type="index" label="序号" :index="indexMethod" width="100"> </el-table-column>
      <el-table-column prop="capitalName" label="资方名称" min-width="120"> </el-table-column>
      <el-table-column prop="capitalMobile" label="手机号" min-width="120"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" min-width="160"> </el-table-column>
      <el-table-column prop="capitalMobile" label="自动分配" min-width="100">
        <template slot-scope="{ row }">
          <el-switch
            :disabled="!isShowBtn(AUTH_BTN.capital_list_status)"
            class="switch"
            @change="changeStatus(row)"
            v-model="row['autoAssign']"
            :active-value="true"
            :inactive-value="false"
            active-text="启用"
            inactive-text="禁用"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="availableAmount" label="账户可用余额" min-width="110"> </el-table-column>
      <el-table-column prop="rechargeAmount" label="累计充值金额" min-width="110"> </el-table-column>
      <el-table-column prop="withdrawAmount" label="累计提现金额" min-width="110"> </el-table-column>
      <el-table-column prop="capitalMobile" label="操作" fixed="right" width="120">
        <template slot-scope="{ row }">
          <el-link
            v-checkbtn="isShowBtn(AUTH_BTN.capital_list_edit)"
            style="margin-right:10px"
            :underline="false"
            type="primary"
            @click="handleEdit(row)"
            >编辑</el-link
          >
          <el-link
            v-checkbtn="isShowBtn(AUTH_BTN.capital_list_recharge)"
            :underline="false"
            type="primary"
            @click="handleRecharge(row)"
            >线下充值</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pageSize" @pagination="getList" />
    <!-- 新增编辑弹窗 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_edit" width="850px">
      <el-form ref="form" :model="formData" label-width="80px">
        <el-form-item label="资方名称" prop="capitalName" :rules="rules">
          <el-input style="width:250px" v-model="formData.capitalName" clearable placeholder="请输入" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="capitalMobile" :rules="rules">
          <el-input style="width:250px" v-model="formData.capitalMobile" clearable placeholder="请输入" size="mini"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_edit = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submit" size="mini">提 交</el-button>
      </div>
    </el-dialog>
    <!-- 线下充值弹窗 -->
    <el-dialog title="线下充值" :visible.sync="show_recharge" width="850px">
      <div class="captial-detail">
        <div class="row">
          <span class="label"> <i class="el-icon-user"></i> 资方名称：</span>
          <span class="value">{{ detail.capitalName }}</span>
        </div>
        <div class="row">
          <span class="label"><i class="el-icon-coin"></i> 账户可用余额：</span>
          <span class="value">{{ detail.availableAmount }}</span>
        </div>
      </div>
      <el-form ref="formRecharge" :model="rechargeData" label-width="80px">
        <el-form-item label="金额：" prop="rechargeAmount" :rules="rules">
          <el-input-number
            style="width:250px"
            v-model="rechargeData.rechargeAmount"
            :precision="2"
            :step="0.01"
            :min="0"
            step-strictly
            size="mini"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input style="width:250px" type="textarea" v-model="rechargeData.remark" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_recharge = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submitRecharge" size="mini">提 交</el-button>
      </div>
    </el-dialog>
    <div style="height:100px"></div>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { getListAPI, changeStatusAPI, delAPI, addAPI, editAPI, getDetailAPI, rechargeAPI, DownloadAPI } from './api'

export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_edit: false,
      show_recharge: false,
      total: 0,
      list: [],
      searchData: {
        page: 1,
        pageSize: 10,
        capitalName: '',
        capitalMobile: '',
        leftCreateDate: '',
        rightCreateDate: ''
      },
      timeSlot: [],
      formData: {
        capitalName: '',
        capitalMobile: ''
      },
      rechargeData: {
        id: '',
        rechargeAmount: '',
        remark: ''
      },
      detail: {}
    }
  },

  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { timeSlot } = this
      let params = { ...this.searchData }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    // 删除
    handleDel({ id }) {
      this.$confirm('此操作将永久删除该资方, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    handleAdd() {
      this.formData = {
        capitalName: '',
        capitalMobile: ''
      }
      this.show_edit = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    async handleEdit(row) {
      this.show_edit = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.formData = await getDetailAPI(row.id)
    },
    // 提交新增编辑
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_edit = false
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.getList()
              this.show_edit = false
            })
          }
        }
      })
    },
    // 启用/禁用
    changeStatus(row) {
      let { id, autoAssign } = row
      let data = { id, autoAssign: autoAssign ? true : false }
      let statusDesc = autoAssign ? '启用' : '禁用'

      this.$confirm(`确认对该资方${statusDesc}自动分配吗, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          row.autoAssign = autoAssign ? true : false
          changeStatusAPI(data).then(() => {
            this.getList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          row.autoAssign = autoAssign ? false : true
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    reset() {
      this.timeSlot = []
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    // 线下充值按钮
    handleRecharge(row) {
      this.detail = row
      this.rechargeData = {
        id: row.id,
        rechargeAmount: '',
        remark: ''
      }
      this.show_recharge = true
      this.$nextTick(() => {
        this.$refs.formRecharge.resetFields()
      })
    },
    // 提交充值
    submitRecharge() {
      this.$refs.formRecharge.validate(valid => {
        if (valid) {
          rechargeAPI(this.rechargeData).then(() => {
            this.$message.success('充值成功')
            this.getList()
            this.show_recharge = false
          })
        }
      })
    },
    handleExport() {
      const { capitalName, capitalMobile } = this.searchData
      const { timeSlot } = this
      let params = { capitalName, capitalMobile }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      DownloadAPI(params)
    },
    // 序号自增--方法
    indexMethod(index) {
      // 拿到页码
      const currentPage = this.searchData.page
      // 拿到每页记录条数
      const pageSize = this.searchData.pageSize
      // 根据值进行计算返回
      return index + 1 + (currentPage - 1) * pageSize
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-dialog {
    .el-dialog__body {
      padding-top: 10px;
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .head-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .el-form {
    .el-form-item {
      margin-right: 25px;
    }
  }
  .captial-detail {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 20px;
    .row {
      display: flex;
      align-items: center;
      margin-right: 40px;
      .label {
        display: flex;
        align-items: center;
        i {
          margin-right: 5px;
        }
      }
      .value {
        font-weight: 600;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
